.App {
  text-align: center;
}

.App-Header p {
  margin: 0;
  padding: 3px 10px;
}

.Logo {
  height: 40px;
}

.Device-Card {
  margin: 10;
}

.Device-Card img {
  margin-top: 0.5em;
  object-fit: scale-down;
}

.Device-Form {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
}

.Device-Form div {
  margin: 0.2em 0
}


.About-Card {
  margin: 10;  
}

.About-Card img {
  width: 40vw;
  object-fit: scale-down;
  margin: auto;
}

.Config-Card .MuiFormControl-root {
  width: 100%;
  margin-top: 1em;
}